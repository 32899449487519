@include breakpoint(lg) {
  [class*=scroll],
  .scroll,
  body {
    --thumb-color:       #d2d3d3;
    --thumb-color-hover: #b8baba;
    --track-color:       #eceded;
    --scroll-size:       10px;
    --scroll-radius:     calc(var(--scroll-size) / 2);
    --display:           block; }
  [class*=scroll]::-webkit-scrollbar,
  .scroll::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: var(--display);
    width:   var(--scroll-size);
    height:  var(--scroll-size); }
  [class*=scroll]::-webkit-scrollbar-thumb,
  .scroll::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb {
    background:    var(--thumb-color);
    border-radius: var(--scroll-radius); }
  [class*=scroll]::-webkit-scrollbar-thumb:hover, [class*=scroll]::-webkit-scrollbar-thumb:active,
  .scroll::-webkit-scrollbar-thumb:hover,
  .scroll::-webkit-scrollbar-thumb:active,
  body::-webkit-scrollbar-thumb:hover,
  body::-webkit-scrollbar-thumb:active {
    background: var(--thumb-color-hover); }
  [class*=scroll]::-webkit-scrollbar-track,
  .scroll::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track {
    background:    var(--track-color);
    border-radius: var(--scroll-radius); }
}
