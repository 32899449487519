.UnderlineNav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid $gray-200;
  justify-content: space-between;
}

.UnderlineNav-body {
  display: flex;
  margin-bottom: -1px;
}

.UnderlineNav-item {
  padding: $spacer-3 $spacer-2;
  margin-right: $spacer-3;
  font-size: $body-font-size;
  line-height: $lh-default;
  color: $text-gray;
  text-align: center;
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus {
    color: $text-gray-dark;
    text-decoration: none;
    border-bottom-color: $gray-300;
    transition: 0.2s ease;

    .UnderlineNav-octicon {
      color: $gray-500;
    }
  }

  &.selected {
    font-weight: $font-weight-bold;
    color: $text-gray-dark;
    border-bottom-color: $orange-600;

    .UnderlineNav-octicon {
      color: $gray-500;
    }
  }
}

.UnderlineNav--right {
  justify-content: flex-end;

  .UnderlineNav-item {
    margin-right: 0;
    margin-left: $spacer-3;
  }

  .UnderlineNav-actions {
    flex: 1 1 auto;
  }
}

.UnderlineNav-actions {
  align-self: center;
}

.UnderlineNav--full {
  display: block;
}

.UnderlineNav-octicon {
  color: $gray-400;
}

.UnderlineNav-container {
  display: flex;
  justify-content: space-between;
}
