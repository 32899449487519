// Padding spacer utilities for marketing
// stylelint-disable block-opening-brace-space-before, declaration-colon-space-before
// stylelint-disable comment-empty-line-before

@each $breakpoint, $variant in $responsive-variants {
    @include breakpoint($breakpoint) {
        @each $scale, $size in $marketing-spacers {
            
            .pt#{$variant}-#{$scale} { padding-top: #{$size} !important; }
            .pb#{$variant}-#{$scale} { padding-bottom: #{$size} !important; }
            .pl#{$variant}-#{$scale} { padding-left: #{$size} !important; }
            .pr#{$variant}-#{$scale} { padding-right: #{$size} !important; }
          
            .py#{$variant}-#{$scale} {
                padding-top: #{$size} !important;
                padding-bottom: #{$size} !important;
            }
        }
    }
}
