// Fixed-width, centered column for site content.
// This will be deprecated and replaced with container-lg in future
.container {
    width: $container-width;
    margin-right: auto;
    margin-left: auto;
    @include clearfix;
}

// Handy container styles that match our breakpoints
// 768px
.container-md {
    max-width: $container-md;
    margin-right: auto;
    margin-left: auto;
}

// 1004px - this matches the current fixed width: 980px + padding: px-3
.container-lg {
    max-width: $container-lg;
    margin-right: auto;
    margin-left: auto;
}

// 1280px
.container-xl {
    max-width: $container-xl;
    margin-right: auto;
    margin-left: auto;
}