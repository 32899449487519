// Color utilities
// stylelint-disable block-opening-brace-space-before, primer/selector-no-utility
// stylelint-disable comment-empty-line-before

// background colors
/* Set the background to $bg-white */
.bg-white      { background-color: $bg-white !important; }
/* Set the background to $bg-blue */
.bg-blue       { background-color: $bg-blue !important; }
/* Set the background to $bg-blue-light */
.bg-blue-light { background-color: $bg-blue-light !important; }
/* Set the background to $bg-gray-dark */
.bg-gray-dark  { background-color: $bg-gray-dark !important; }
/* Set the background to $bg-gray */
.bg-gray       { background-color: $bg-gray !important; }
/* Set the background to $bg-gray-light */
.bg-gray-light { background-color: $bg-gray-light !important; }
/* Set the background to $bg-green */
.bg-green      { background-color: $bg-green !important; }
/* Set the background to $bg-green-light */
.bg-green-light     { background-color: $bg-green-light !important; }
/* Set the background to $bg-red */
.bg-red        { background-color: $bg-red !important; }
/* Set the background to $bg-red-light */
.bg-red-light  { background-color: $bg-red-light !important; }
/* Set the background to $bg-yellow */
.bg-yellow     { background-color: $bg-yellow !important; }
/* Set the background to $bg-yellow-light */
.bg-yellow-light     { background-color: $bg-yellow-light !important; }
/* Set the background to $bg-purple */
.bg-purple     { background-color: $bg-purple !important; }
/* Set the background to $bg-purple-light */
.bg-purple-light     { background-color: $bg-purple-light !important; }

.bg-shade-gradient {
    background-image: linear-gradient(180deg, rgba($black, 0.065), rgba($black, 0)) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 200px !important;
}

// text colors
/* Set the text color to $text-blue */
.text-blue          { color: $text-blue !important; }
/* Set the text color to $text-red */
.text-red           { color: $text-red !important; }
/* Set the text color to $text-gray-light */
.text-gray-light    { color: $text-gray-light !important; }
/* Set the text color to $text-gray */
.text-gray          { color: $text-gray !important; }
/* Set the text color to $text-gray-dark */
.text-gray-dark     { color: $text-gray-dark !important; }
/* Set the text color to $text-green */
.text-green         { color: $text-green !important; }
/* Set the text color to $text-orange */
.text-orange        { color: $text-orange !important; }
/* Set the text color to $text-orange-light */
.text-orange-light        { color: $text-orange-light !important; }
/* Set the text color to $text-purple */
.text-purple        { color: $text-purple !important; }
/* Set the text color to $text-white */
.text-white         { color: $text-white !important; }
/* Set the text color to inherit */
.text-inherit       { color: inherit !important; }

// Text states
// These can probably all be regular utilities
.text-pending { color: $yellow-800 !important; }
// Separate text and background colors in future to improve a11y
.bg-pending { color: $yellow-700 !important; }

// Link colors
// Sets the links color to $text-gray and $text-blue on hover
.link-gray {
    color: $text-gray !important;
    
    &:hover {
        color: $text-blue !important;
    }
}

// Sets the links color to $text-gray-dark and $text-blue on hover
.link-gray-dark {
    color: $text-gray-dark !important;
    
    &:hover {
        color: $text-blue !important;
    }
}

/* Set the link color to $text-blue on hover
  Useful when you want only part of a link to turn blue on hover */
.link-hover-blue {
    &:hover {
        color: $text-blue !important;
    }
}

/* Make a link $text-gray, then $text-blue on hover and removes the underline */
.muted-link {
    color: $text-gray !important;
    
    &:hover {
        color: $text-blue !important;
        text-decoration: none;
    }
}