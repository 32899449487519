abbr, address, article, aside, audio, b, blockquote, body, body div, caption, cite, code, dd, del, details, dfn, dl, dt, em, fieldset, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, span, summary, tbody, tfoot, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: 400;
    vertical-align: baseline;
    background: 0 0;
}

a,
small,
strong,
sub,
sup,
table,
td,
th {
    margin: 0;
    padding: 0;
    background: 0 0;
}

small,
sub,
sup {
    border: 0;
    font-weight: 400;
}

strong {
    font-size: 100%
}

.ie7 input[type=checkbox],
small,
strong,
sub,
sup,
table {
    vertical-align: baseline;
}

a,
td,
th {
    font-size: 100%
}

strong,
table,
td,
th {
    border: 0
}

article,
aside,
details,
figure,
footer,
header,
main,
nav,
section,
summary {
    display: block;
}

html {
    box-sizing: border-box;
    overflow-y: scroll;
}

*,
:after,
:before {
    box-sizing: inherit;
}

embed,
img,
object {
    max-width: 100%;
}

ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: none;
}

a {
    vertical-align: baseline;
    text-decoration: none;
}

a.active,
a:focus,
a:hover {
    text-decoration: none;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted #000;
    cursor: help
}

table {
    border-collapse: separate; //collapse
    border-spacing: 0;
}

td {
    font-weight: 400;
}

input,
select {
    vertical-align: middle;
}

pre {
    white-space: pre-line;
    word-wrap: break-word;
}

input[type=radio] {
    vertical-align: text-bottom;
}

input[type=checkbox],
th {
    vertical-align: bottom;
}

input,
select,
textarea,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

small {
    font-size: 85%;
}

b,
strong,
th {
    font-weight: $font-weight-bold;
}

td, td img {
    vertical-align: top;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

code,
kbd,
pre,
samp {
    font-family: monospace, sans-serif;
}

.clickable,
button,
input[type=button],
input[type=file],
input[type=submit],
label {
    cursor: pointer;
}

button,
input,
select,
textarea {
    margin: 0
}

button,
input[type=button] {
    width: auto;
    overflow: visible;
}

// increase the selector specificity for [hidden]
// so that it always overrides utility classes (.d-block, etc.)
[hidden][hidden] {
    display: none !important;
}