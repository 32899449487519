.btn-mktg {
  display:          inline-block;
  padding:          $spacer-3 $spacer-4;
  font-size:        $h5-size;
  font-weight:      $font-weight-semibold;
  color:            $white;
  text-transform:   capitalize;
  white-space:      nowrap;
  vertical-align:   middle;
  cursor:           pointer;
  user-select:      none;
  background-color: $blue-500;
  border:           1px solid $blue-500;
  border-radius:    $border-radius;
  transition:       $transition-time / 2;
  appearance:       none; // Corrects inability to style clickable `input` types in iOS.
  
  &:hover {
    text-decoration:  none;
    background-color: $blue-600;
    border-color:     $blue-600;
  }
  
  &:focus {
    outline:    0;
    box-shadow: 0 0 0 0.2em rgba($blue-600, 0.3);
  }
  
  &:disabled,
  &.disabled {
    pointer-events: none; // Disable hover styles
    cursor:         default;
    opacity:        0.65;
  }
}

.btn-secondary-mktg {
  background-color: $green-500;
  border-color:     $green-500;
  
  &:hover {
    background-color: $green-600;
    border-color:     $green-600;
  }
  
  &:focus {
    box-shadow: 0 0 0 0.2em rgba($green-600, 0.3);
  }
}

.btn-large-mktg {
  padding:   20px $spacer-5;
  font-size: $h4-size;
}

.btn-outline-mktg {
  color:            $blue-450;
  background-color: $white;
  border-color:     rgba($blue-500, 0.5);
  
  &:hover {
    color:            $blue-600;
    text-decoration:  none;
    background-color: $white;
    border-color:     rgba($blue-500, 1);
  }
}
