// Button group
//
// A button group is a series of buttons laid out next to each other, all part
// of one visual button, but separated by rules to be separate.
@warn ".BtnGroup-form will be deprecated in version 11. Use .BtnGroup-parent instead.";

.BtnGroup {
    display: inline-block;
    vertical-align: middle;
    @include clearfix();
    
    // Proper spacing for multiple button groups (a la, gollum editor)
    + .BtnGroup,
    + .btn {
        margin-left: $spacer-1;
    }
}

.BtnGroup-item {
    position: relative;
    float: left;
    border-right-width: 0;
    border-radius: 0;
    
    &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    
    &:last-child {
        border-right-width: $border-width;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    
    &.selected,
    &:focus,
    &:active,
    &:hover {
        border-right-width: $border-width;
        
        + .BtnGroup-item,
        + .BtnGroup-parent .BtnGroup-item,
        + .BtnGroup-form .BtnGroup-item {
            border-left-width: 0;
        }
    }
}

.BtnGroup-parent,
.BtnGroup-form {
    float: left;
    
    &:first-child .BtnGroup-item {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    
    &:last-child .BtnGroup-item {
        border-right-width: $border-width;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    
    .BtnGroup-item {
        border-right-width: 0;
        border-radius: 0;
    }
    
    &.selected,
    &:focus,
    &:active,
    &:hover {
        .BtnGroup-item {
            border-right-width: $border-width;
        }
        
        + .BtnGroup-item,
        + .BtnGroup-parent .BtnGroup-item,
        + .BtnGroup-form .BtnGroup-item {
            border-left-width: 0;
        }
    }
}

// ensure that the focus ring sits above the adjacent buttons
.BtnGroup-item,
.BtnGroup-parent,
.BtnGroup-form {
    &:focus,
    &:active {
        z-index: 1;
    }
}
