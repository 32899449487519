// stylelint-disable block-closing-brace-newline-after

// Button color generator for primary and themed buttons

// New button hotness
@mixin btn-solid($color, $bg) {
  color:            $color;
  background-color: $bg;
  border: 1px solid transparent;
  
  &:hover,
  &.hover {
    background-color: darken($bg, 3%);
  }

  &:active,
  &.selected,
  [open] > & {
    background-color: darken($bg, 7%);
  }
  
  &:focus,
  &.focus {
    box-shadow: inset 0 0 0 1px darken($bg, 15%);
    border:           1px solid darken($bg, 15%);
  }
  
  &:disabled,
  &.disabled {
    pointer-events: none;
    border: 1px solid $border-gray-dark;
    color: $border-gray-dark;
    background-color: transparent;
  }
}

@mixin btn-outline($text-color: $text-blue, $bg-color: $bg-white) {
  color:            $text-color;
  background-color: $bg-color;
  background-image: none;
  
  .Counter {
    background-color: rgba($black, 0.07);
  }
  
  &:hover,
  &:active,
  &.selected,
  [open] > & {
    color:            $bg-color;
    background-color: $text-color;
    background-image: none;
    border-color:     $text-color;
    
    .Counter {
      color:            $text-color;
      background-color: $bg-color;
    }
  }
  
  &:focus {
    border-color: $text-color;
    box-shadow:   0 0 0 0.2em rgba($text-color, 0.4);
  }
  
  &:disabled,
  &.disabled {
    color:            $black-fade-30;
    background-color: $bg-white;
    border-color:     $black-fade-15;
    box-shadow:       none;
  }
}
