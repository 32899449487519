body {
    font-family: $body-font;
    font-size: $body-font-size;
    line-height: $body-line-height;
    color: $text-gray-dark;
    background-color: $bg-gray;
}

html, body{
  min-height: 100%;
  width:      100%;
}

