// Outer header container
.Header {
  background-color: $white;
  padding-bottom:   $spacer-1;
  padding-top:      $spacer-1;
  z-index:          32;
}

// Hidde menu in mobile by default
.page-responsive .HeaderMenu {
  //clear:   both;
  display: none;
}

// Show menu when hamburguer icon is clicked
.open .HeaderMenu {
  display: block;
}

.edge-item-fix {
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

.page-responsive .HeaderMenu--logged-out {
  background-color: $white;
  box-shadow:       0 10px 50px rgba(27, 31, 35, 0.15);
  overflow:         auto;
  width:            300px;
  z-index:          100;
}

.page-responsive .HeaderMenu--logged-out .dropdown-menu {
  border:     0 solid transparent;
  box-shadow: none;
  position:   static;
  width:      auto;
}

.page-responsive .HeaderMenu--logged-out .dropdown-menu-s {
  transform: none;
}

.page-responsive .HeaderMenu-link {
  background: transparent;
  color:      #444d56;
}

.page-responsive .HeaderMenu-link:hover {
  color: #24292e;
}

// icon arrow down
.page-responsive .HeaderMenu-link .icon-chevon-down-mktg {
  right:      0;
  stroke:     #24292e;
  top:        24px;
  transition: stroke .4s;
  width:      14px;
}

.page-responsive .HeaderMenu-details[open] > summary:before {
  display: none;
}

.page-responsive .HeaderMenu-details[open] .HeaderMenu-link {
  color: #24292e;
}

.page-responsive .HeaderMenu-details[open] .dropdown-menu {
  animation: none;
}

.page-responsive .HeaderMenu-details[open] .icon-chevon-down-mktg {
  stroke: #24292e;
}

.HeaderMenu-link {
  background: transparent;
  color:      $text-gray-dark;
  transition: .4s;
}

.HeaderMenu-link:hover {
  color: rgba($text-gray-dark, 0.75);
}

.HeaderMenu-link .icon-chevon-down-mktg {
  right:      0;
  stroke:     rgba($text-gray-dark, 0.5);
  top:        -2px;
  transition: stroke .4s;
  width:      12px;
}

.HeaderMenu-details[open] .HeaderMenu-link {
  color: rgba($text-gray-dark, 0.75);
}

.HeaderMenu-details[open] .dropdown-menu {
  animation: dropdown-display 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
}

.HeaderMenu-details[open] .icon-chevon-down-mktg {
  stroke: $text-gray-dark;
}

.HeaderMenu-summary::-webkit-details-marker {
  display: none;
}

@keyframes dropdown-display {
  0% {
    opacity:   0;
    transform: scale(0.98) translateY(-0.6em);
  }
  to {
    opacity:   1;
    transform: scale(1) translateY(0);
  }
}

.HeaderNavlink {
  color:   $text-gray-dark;
  display: block;
}

.page-responsive .HeaderNavlink {
  border-top: 1px solid #444d56;
}

.HeaderNavlink:focus, .HeaderNavlink:hover {
  color:           rgba($text-gray-dark, 0.7);
  text-decoration: none;
}

.HeaderNavlink:focus .dropdown-caret, .HeaderNavlink:hover .dropdown-caret {
  border-top-color: rgba($text-gray-dark, 0.7);
}

.HeaderNavlink.selected {
  color: $text-gray-dark;
}

.HeaderNavlink.selected.js-menu-target {
  border-bottom-color: transparent;
}

.HeaderNavlink.selected.tooltipped:after, .HeaderNavlink.selected.tooltipped:before {
  display: none;
}


@media (min-width: 1012px) {
  .page-responsive .HeaderMenu {
    clear:   none;
    display: block;
  }
  .page-responsive .HeaderMenu--logged-out {
    //background-color: transparent;
    box-shadow:       none;
    overflow:         visible;
    width:            auto;
  }
  .page-responsive .HeaderMenu--logged-out .dropdown-menu {
    border:     0;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15), 0 0 1px rgba(27, 31, 35, 0.2);
    position:   absolute;
    white-space: nowrap;
    //width:      300px;
  }
  .page-responsive .HeaderMenu--logged-out .dropdown-menu:after, .page-responsive .HeaderMenu--logged-out .dropdown-menu:before {
    content: "";
  }
  .page-responsive .HeaderMenu--logged-out .dropdown-menu-s {
    transform: translateX(50%);
  }
  .page-responsive .HeaderMenu-link {
    color:      $text-gray-dark;
    transition: .4s;
  }
  .page-responsive .HeaderMenu-link:hover {
    color: rgba($text-gray-dark, 0.75);
  }
  .page-responsive .HeaderMenu-link .icon-chevon-down-mktg {
    background: transparent;
    stroke:     rgba($text-gray-dark, 0.5);
    top:        -2px;
    width:      12px;
  }
  .page-responsive .HeaderMenu-details[open] > summary:before {
    bottom:   -8px;
    display:  block;
    position: absolute;
  }
  .page-responsive .HeaderMenu-details[open] .HeaderMenu-link {
    color: rgba($text-gray-dark, 0.75);
  }
  .page-responsive .HeaderMenu-details[open] .dropdown-menu {
    animation: dropdown-display 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
  }
  .page-responsive .HeaderMenu-details[open] .icon-chevon-down-mktg {
    stroke: $text-gray-dark;
  }
  .page-responsive .HeaderNavlink {
    border-top: 0;
  }
}
