// Responsive media queries

@mixin breakpoint($breakpoint) {
    @if $breakpoint == "" {
        @content;
    }
    
    @else {
        // Retrieves the value from the key
        $value: map-get($breakpoints, $breakpoint);
        
        // If the key exists in the map
        @if $value != null {
            // Prints a media query based on the value
            @media (min-width: $value) {
                @content;
            }
        }
            
            // If the key doesn't exist in the map
        @else {
            @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
        }
    }
}

// Retina media query

@mixin retina-media-query {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}

// Clearfix
//
// Clears floats via mixin.

@mixin clearfix {
    &::before {
        display: table;
        content: "";
    }
    
    &::after {
        display: table;
        clear: both;
        content: "";
    }
}