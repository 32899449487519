//
//
// -------- Grays --------
$gray-000:        #fafbfc !default;
$gray-100:        #f6f8fa !default;
$gray-200:        #e1e4e8 !default;
$gray-300:        #d1d5da !default;
$gray-400:        #959da5 !default;
$gray-500:        #6a737d !default;
$gray-600:        #586069 !default;
$gray-700:        #444d56 !default;
$gray-800:        #2f363d !default;
$gray-900:        #24292e !default; // body font color

// -------- Blue --------
$blue-000:        #f3f8ff !default;
$blue-100:        #d9eaff !default;
$blue-200:        #b3d6ff !default;
$blue-300:        #99c3ff !default;
$blue-400:        #4d96ff !default;
$blue-500:        #0055ff !default; // Default: Passes AA with #fff
$blue-600:        #0053d8 !default;
$blue-700:        #0044b2 !default;
$blue-800:        #00398c !default;
$blue-900:        #002766 !default; // Passes with 1/2/300 blues

// -------- Green --------
$green-000:       #f0fff4 !default;
$green-100:       #ccf3dc !default;
$green-200:       #99e6b8 !default;
$green-300:       #66da95 !default;
$green-400:       #33cd71 !default;
$green-500:       #00c14e !default; // Default. passes AA Large
$green-600:       #009a3e !default; // Text green, passes AA on #fff
$green-700:       #00742F !default;
$green-800:       #004D1F !default;
$green-900:       #002710 !default;

// -------- Yellow --------
$yellow-000:      #fffdef !default;
$yellow-100:      #fff9d1 !default;
$yellow-200:      #fff3a4 !default;
$yellow-300:      #ffec76 !default;
$yellow-400:      #ffe649 !default;
$yellow-500:      #ffe01b !default;
$yellow-600:      #ccb316 !default;
$yellow-700:      #998610 !default;
$yellow-800:      #665a0b !default;
$yellow-900:      #332d05 !default;

// -------- Orange --------
$orange-000:      #fff8f2 !default;
$orange-100:      #ffebda !default;
$orange-200:      #ffd1ac !default;
$orange-300:      #ffab70 !default;
$orange-400:      #fb8532 !default;
$orange-500:      #f66a0a !default; // Default. passes AA Large with #fff
$orange-600:      #e36209 !default;
$orange-700:      #d15704 !default;
$orange-800:      #c24e00 !default;
$orange-900:      #a04100 !default;

// -------- Red --------
$red-000:         #ffeef0 !default;
$red-100:         #fcdfd3 !default;
$red-200:         #fabfa8 !default;
$red-300:         #f79f7c !default;
$red-400:         #f57f51 !default;
$red-500:         #f25f25 !default; // Default. passes AA
$red-600:         #c24c1e !default;
$red-700:         #913916 !default;
$red-800:         #61260f !default;
$red-900:         #301307 !default;

// -------- Purple --------
$purple-000:      #f5f0ff !default;
$purple-100:      #e6dcfd !default;
$purple-200:      #d1bcf9 !default;
$purple-300:      #b392f0 !default;
$purple-400:      #8a63d2 !default;
$purple-500:      #6f42c1 !default; // passes AA with #fff
$purple-600:      #5a32a3 !default;
$purple-700:      #4c2889 !default;
$purple-800:      #3a1d6e !default;
$purple-900:      #29134e !default;

// -------- Fades --------
// Black based on same hue as $gray-900
$black: #1b1f23 !default;
$white: #fff !default;

$black-fade-15:      rgba($black, 0.15) !default;
$black-fade-30:      rgba($black, 0.3) !default;
$black-fade-50:      rgba($black, 0.5) !default;
$black-fade-70:      rgba($black, 0.7) !default;
$black-fade-85:      rgba($black, 0.85) !default;

$white-fade-15:      rgba($white, 0.15) !default;
$white-fade-30:      rgba($white, 0.3) !default;
$white-fade-50:      rgba($white, 0.5) !default;
$white-fade-70:      rgba($white, 0.7) !default;
$white-fade-85:      rgba($white, 0.85) !default;

// -------- Color defaults --------
$red:         $red-500 !default;
$purple:      $purple-500 !default;
$blue:        $blue-500 !default;
$green:       $green-500 !default;
$yellow:      $yellow-500 !default;
$orange:      $orange-500 !default;

$gray-dark:   $gray-900 !default;
$gray-light:  $gray-000 !default;
$gray-hover:  $gray-200 !default;
$gray-active: $gray-400 !default;
$gray:        $gray-500 !default;
